import React, {useEffect} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {ChakraProvider} from '@chakra-ui/react';
import {BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom';
import setLoginTokenAction from './Services/setLoginToken';
import {getLoggedIn, getQRCode} from './Services/reducers.js';

import Styles from './Views/Styles';
import theme from './Theme/theme';

import Login from './Views/login/Login';
import Main from './Views/main/Main';
import Subscribe from './Views/subscribe/Subscribe';

function App({...props}) {
  const {loggedIn} = props.loggedIn;
  const {setLoginToken} = props;
  useEffect(() => {
    setLoginToken(`${process.env.REACT_APP_TOKEN_NAME}`);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ChakraProvider theme={theme}>
      <Router>
        <Switch>
          <Route strict path="/styles">
            {loggedIn ? <Styles /> : <Redirect to="/login" />}
          </Route>
          <Route strict path="/subscribe">
            {loggedIn ? <Subscribe /> : <Redirect to="/login" />}
          </Route>
          <Route strict path="/stamps">
            {loggedIn ? <Main /> : <Redirect to="/login" />}
          </Route>
          <Route strict path="/login">
            {loggedIn ? <Redirect to="/stamps" /> : <Login />}
          </Route>
          <Route exact path="/">
            {loggedIn ? <Redirect to="/stamps" /> : <Redirect to="/login" />}
          </Route>
        </Switch>
      </Router>
    </ChakraProvider>
  );
}

const mapStateToProps = (state) => ({
  loggedIn: getLoggedIn(state),
  hasActiveQR: getQRCode(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setLoginToken: setLoginTokenAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(App);
