import React, {StrictMode} from 'react';
import ReactDOM from 'react-dom';
import {Router} from 'react-router-dom';
import {Provider as ReduxProvider} from 'react-redux';
import {createBrowserHistory as createHistory} from 'history';

import 'focus-visible/dist/focus-visible';

import App from './App';
import configureStore from './Services/store';

const store = configureStore();

ReactDOM.render(
  <StrictMode>
    <ReduxProvider store={store}>
      <Router history={createHistory()}>
        <App />
      </Router>
    </ReduxProvider>
  </StrictMode>,
  document.getElementById('root'),
);
