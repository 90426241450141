import Cookies from 'js-cookie';
import {logout, setLoggedIn} from './actions.js';

const handleLogout = () => {
  console.log('logging out');
  return (dispatch) => {
    try {
      dispatch(logout());
      dispatch(setLoggedIn(false));
    }catch(err) {
      console.log('error while trying to logout', err);
    }
    try {
      Cookies.remove(`${process.env.REACT_APP_TOKEN_NAME}`);
    }catch(err) {
      console.log('error while removing token-cookie', err);
    }
  };
}
export default handleLogout;
