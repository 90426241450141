import Cookies from 'js-cookie';
import {
  fetchLoginTokenError,
  fetchLoginTokenPending,
  fetchLoginTokenSuccess
} from './actions.js';

const LOGIN_URL = `${process.env.REACT_APP_API_URL}/login`;

const LOGIN_CONFIG = {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
};

function fetchLoginToken(body = {}) {
  return (dispatch) => {
    dispatch(fetchLoginTokenPending());
    fetch(LOGIN_URL, {...LOGIN_CONFIG, body: JSON.stringify(body)})
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.token) {
        Cookies.set(process.env.REACT_APP_TOKEN_NAME, res.token);
          dispatch(fetchLoginTokenSuccess(res.token));
        } else {
          dispatch(fetchLoginTokenError(res.errors));    
        }
      })
      .catch((error) => {
        dispatch(fetchLoginTokenError(error));
      });
  };
}
export default fetchLoginToken;
