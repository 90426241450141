import React from 'react';
import {Link} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {HStack, Button, Box} from '@chakra-ui/react';
import handleLogout from '../../Services/handleLogout';

const Navbar = ({...props}) => {
  const {logout} = props;
  return (
    <HStack mb={6} justify="space-between">
      <Box>
        <Link to="/">
          <Button variant="primary" color="white" textStyle={`h3`} mb={2} mr={4}>
            Leimat
          </Button>
        </Link>
        <Link to="/subscribe">
          <Button variant="primary" color="white" textStyle={`h3`} mb={2}>
            Aktivoinnit
          </Button>
        </Link>
      </Box>
      <Button variant="primary" color="white" textStyle={`h3`} mb={2} onClick={() => {logout()}}>
        Kirjaudu ulos
      </Button>
    </HStack>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logout: handleLogout,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
