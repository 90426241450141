import {
  fetchRolesError,
  fetchRolesPending,
  fetchRolesSuccess,
  setLoggedIn,
} from './actions.js';

const LOGIN_URL = `${process.env.REACT_APP_API_URL}/roles?perPage=9&page=1`;

const formLoginConfig = (token = '') => ({
  // method: 'GET',
  headers: {
    Accept: 'application/json',
    Authorization: `Bearer ${token}`,
  },
});

function fetchRoles(token = '') {
  return (dispatch) => {
    dispatch(fetchRolesPending());
    fetch(LOGIN_URL, {...formLoginConfig(token)})
      .then((res) => {
        if (res.status === 401) {
          dispatch(setLoggedIn(false));
        } else {
          return res.json();
        }
      })
      .then((res) => {
        if (res.data) {
          dispatch(fetchRolesSuccess(res.data));
        } else {
          dispatch(fetchRolesError(res.message));
        }
      })
      .catch((error) => {
          console.log(error);
        dispatch(fetchRolesError(error));
      });
  };
}
export default fetchRoles;
