const Input = {
  parts: ["field"],
  sizes: {
    lg: {
      field: {
        fontSize: "sm",
        pl: 4,
        pr: 4,
        h: 14,
      }
    },
    sm: {
      field: {
        borderRadius: "base",
      }
    }
  },
  variants: {
    outline: {
      field: {
        borderColor: "gray.600",
        _hover: {
          borderColor: "gray.700",
        },
        _focus: {
          borderColor: "gray.600",
          bgColor: "gray.200",
          boxShadow: "md",
        },
      }
    }

  },
  defaultProps: {
    size: "lg",
  },
}

export default Input;