import {
  fetchStampCardsError,
  fetchStampCardsPending,
  fetchStampCardsSuccess,
  setLoggedIn
} from './actions.js';

const LOGIN_URL = `${process.env.REACT_APP_API_URL}/stampCards?perPage=9&page=1`;

const formLoginConfig = (token) => ({
  // method: 'GET',
  headers: {
    "Accept": 'application/json',
    "Authorization": `Bearer ${token}`,
  },
});

function fetchStampCards(token = '') {
  return (dispatch) => {
    dispatch(fetchStampCardsPending());
    fetch(LOGIN_URL, {...formLoginConfig(token)})
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.data) {
          dispatch(fetchStampCardsSuccess(res.data));
        } else {
          dispatch(fetchStampCardsError(res.message));
        }
      })
      .catch((error) => {
        dispatch(fetchStampCardsError(error));
        dispatch(setLoggedIn(false));
      });
  };
}
export default fetchStampCards;
