import Cookies from 'js-cookie';
import {setLoggedIn, setLoginTokenFromCookie} from './actions.js';

function setLoginToken(cookieName = '') {
  const cookie = Cookies.get(cookieName);
  return (dispatch) => {
    if (cookie !== undefined) {
      console.log('setting from cookie');
      dispatch(setLoggedIn(true));
      dispatch(setLoginTokenFromCookie(cookie));
    } else {
      console.log('logged in = false');
      dispatch(setLoggedIn(false));
    }
  };
}
export default setLoginToken;
