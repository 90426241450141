const colors =  {

  transparent: "transparent",
  current: "currentColor",
  black: "#000",
  white: "#fff",

  whiteAlpha: {
    50: "rgba(255, 255, 255, 0.04)",
    100: "rgba(255, 255, 255, 0.06)",
    200: "rgba(255, 255, 255, 0.08)",
    300: "rgba(255, 255, 255, 0.16)",
    400: "rgba(255, 255, 255, 0.24)",
    500: "rgba(255, 255, 255, 0.36)",
    600: "rgba(255, 255, 255, 0.48)",
    700: "rgba(255, 255, 255, 0.64)",
    800: "rgba(255, 255, 255, 0.80)",
    900: "rgba(255, 255, 255, 0.92)",
  },

  blackAlpha: {
    50: "rgba(0, 0, 0, 0.04)",
    100: "rgba(0, 0, 0, 0.06)",
    200: "rgba(0, 0, 0, 0.08)",
    300: "rgba(0, 0, 0, 0.16)",
    400: "rgba(0, 0, 0, 0.24)",
    500: "rgba(0, 0, 0, 0.36)",
    600: "rgba(0, 0, 0, 0.48)",
    700: "rgba(0, 0, 0, 0.64)",
    800: "rgba(0, 0, 0, 0.80)",
    900: "rgba(0, 0, 0, 0.92)",
  },

  red: {
    100: "#FCD8C9",
    200: "#F9AA95",
    300: "#EF6F5F",
    400: "#E03B38",
    500: "#CC000E",
    600: "#AF001B",
    700: "#920024",
    800: "#760028",
    900: "#610029",
   },

  gray: {
    100: "#FFFFFF",
    200: "#F8F9FB",
    300: "#EEF1F6",
    400: "#E6E9F0",
    500: "#CAD0DC",
    600: "#989EA9",
    700: "#353D52",
    800: "#272D40",
    900: "#1D2334",
    1000: "#181C2D",
    1100: "#101426",
  },
 
 warning: {
    100: "#FFF4CC",
    200: "#FFE799",
    300: "#FFD666",
    400: "#FFC53F",
    500: "#FFAA00",
    600: "#DB8A00",
    700: "#B76D00",
    800: "#935300",
    900: "#7A4000",
  },

  danger: {
    100: "#FDE5D7",
    200: "#FBC5B0",
    300: "#F59C87",
    400: "#EC7567",
    500: "#E03B38",
    600: "#C02833",
    700: "#A11C30",
    800: "#81112C",
    900: "#6B0A2A",
  }

}

export default colors;