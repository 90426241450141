const layerStyles = {
    box: {
      bg: "white",
      boxShadow: "xl",
      borderRadius: "2xl"
    },
    boxNotice: {
      bg: "red.600",
      borderRadius: "2xl"
    },
  };

  export default layerStyles;