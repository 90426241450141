import React from 'react';

import { 
    Box,
    Center,
    Text,
    HStack,
    Button,
    Icon,
    FormControl,
    FormLabel,
    Input,
    IconButton,
 } from "@chakra-ui/react";

 import { 
   FiPlus,
   FiMinus,
   FiUser
   } from "react-icons/fi"
   
 import {ReactComponent as Logo} from '../Theme/Assets/Logo/Logo.svg';

 // Custom icons
import {ReactComponent as CoffeeIcon} from '../Theme/Assets/Icons/coffee.svg';
import {ReactComponent as FoodIcon} from '../Theme/Assets/Icons/food.svg';
import {ReactComponent as TruckIcon} from '../Theme/Assets/Icons/truck.svg';
import {ReactComponent as StaffIcon} from '../Theme/Assets/Icons/staff.svg';


const Styles = () => {
  return (
    <>
      <Box p={12}>

        <HStack spacing={6}>

          <Center w={1/3}>
              <Logo />
          </Center>

          <Box layerStyle="box" p={6} w={1/3}>
              <Text textStyle={`label`} mb={4} color="gray.600">Textstyles</Text>

              <Text textStyle={`h1`} mb={2}>Heading 1</Text>
              <Text textStyle={`h2`} mb={2}>Heading 2</Text>
              <Text textStyle={`h3`} mb={2}>Heading 3</Text>
              <Text textStyle={`body`} mb={2}>Body</Text>
              <Text textStyle={`small`} mb={2}>Small</Text>
              <Text textStyle={`label`} mb={2}>Label</Text>
              <Text textStyle={`caption`} mb={2}>Caption</Text>
          </Box>

          <Box layerStyle="box" p={6} w={1/3}>
              <Text textStyle={`label`} mb={4} color="gray.600">Buttons</Text>
              <Button variant="primary">1</Button>
              <Button variant="primary">2</Button>
              <Button variant="solid">Luo QR-koodi</Button>
              <IconButton aria-label="Plus"  variant="outline" colorScheme="gray" icon={<FiPlus />} />
              <IconButton aria-label="Minus" variant="outline" icon={<FiMinus />} />

          </Box>
          
        </HStack>



        <HStack spacing={6} mt={12}>
          <Box layerStyle="box" p={6} w={1/3}>
            <Text textStyle={`label`} mb={4} color="gray.600">Forms</Text>

            <HStack spacing={2} my={6}>
              <IconButton aria-label="Plus"  variant="outline" colorScheme="gray" icon={<FiPlus />} />
              <Input variant="outline" placeholder="Outline" value="0" />
              <IconButton aria-label="Minus" variant="outline" icon={<FiMinus />} />
            </HStack>

            <FormControl id="email">
              <FormLabel textStyle={`label`} color="gray.600">Email address</FormLabel>
              <Input type="email" />
            </FormControl>
              
          </Box>


          <Box layerStyle="boxNotice" p={6} w={1/3}>
            <Text textStyle={`label`} mb={4} color="white">Notice box</Text>
            <Text textStyle={`caption`} color="white">Subheading</Text>
            <Text textStyle={`h3`} color="white">Main info</Text>
          </Box>

          <Box layerStyle="box" p={6} w={1/3}>
            <Text textStyle={`label`} mb={4} color="gray.600">UI Icons</Text>

            <HStack spacing={2} my={6}>
              <Icon as={FiPlus} w={8} h={8} color="red.500" />
              <Icon as={FiMinus} w={8} h={8} color="red.500" />
              <Icon as={FiUser} w={8} h={8} color="red.500" />
            </HStack>

            <Text textStyle={`label`}>Feather icons</Text>
            <Text textStyle={`caption`}>https://react-icons.github.io/react-icons/icons?name=fi</Text>
            
          </Box>

        </HStack>

        <HStack spacing={6} mt={12}>
          <Box layerStyle="box" p={6} w="50%">
            <Text textStyle={`label`} mb={4} color="gray.600">Custom Icons</Text>
           
            <HStack spacing={4} my={6}>
              <Icon as={CoffeeIcon} w={24} h={24} color="red.500" />
              <Icon as={FoodIcon} w={24} h={24} color="red.500" />
              <Icon as={TruckIcon} w={24} h={24} color="red.500" />
              <Icon as={StaffIcon} w={24} h={24} color="red.500" />
            </HStack>
          </Box>
        </HStack>


        

      </Box>
    </>
  );
}

export default Styles;
