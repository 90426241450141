// import Cookies, { get } from 'js-cookie';
import {fetchQRCodeError, fetchQRCodePending, fetchQRCodeSuccess} from './actions.js';

const QR_URL = `${process.env.REACT_APP_API_URL}`;

function fetchQRCode(apiEndpoint = '', request = {}) {
  return (dispatch) => {
    dispatch(fetchQRCodePending());
    fetch(`${QR_URL}/${apiEndpoint}`, request)
      .then((res) => {
        switch(res.status) {
          case 201: 
            return res.json();
          case 200:
            return res.json();
          default:
            return dispatch(fetchQRCodeError(res.status));
        }
      })
      .then((res) => {
        if (res.data) {
          dispatch(fetchQRCodeSuccess(res.data));
        } else {
          dispatch(fetchQRCodeError(res.errors));
        }
      })
      .catch((error) => {
        dispatch(fetchQRCodeError(error));
      });
  };
}
export default fetchQRCode;
