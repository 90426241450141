const Button = {
  // The styles all button have in common
  baseStyle: {
    fontWeight: "bold",
    textTransform: "uppercase"
  },
  // Two sizes: sm and md
  sizes: {
    sm: {
      fontSize: "sm",
      borderRadius: "md"
    },
    lg: {
      fontSize: "md",
      borderRadius: "lg",
      lineHeight: 4,
      h: 14
    },
  },
  // Two variants: outline and solid
  variants: {

    solid:{
        bg: "gray.100",
        color: "gray.800",
        boxShadow: "md",
        _hover: {
            bg: "gray.300",
            color: "gray.900",
        },
        _active: {
            bg: "gray.500",
            color: "gray.900",
        },
        _focus: {
          bg: "gray.300",
          color: "gray.900",
        },
    },

    primary: {
      bg: "red.500",
      color: "white",
      boxShadow: "base",
      _hover: {
        bg: "red.600",
        color: "white",
      },
      _active: {
        bg: "red.700",
        color: "white",
      },
      _focus: {
        bg: "red.600",
        color: "white",
      },
    },

    outline: {
      color: "gray.600",
      border: "1px solid",
      borderColor: "gray.600",
      _hover: {
        bg: "gray.300",
      },
      _active: {
        bg: "gray.400",
      },
      _focus: {
        bg: "gray.300",
      },
    },



  },
  // The default size and variant values
  defaultProps: {
    size: "lg",
  },
}

export default Button;