// https://github.com/chakra-ui/chakra-ui/blob/52230c5ac62301993ae6ac47ad658987e5822581/packages/chakra-ui/src/theme/theme.js#L8
import { extendTheme } from "@chakra-ui/react"

import global from "./global";

import colors from "./Foundations/color";
import shadows from "./Foundations/shadows";
import typography from "./Foundations/typography";
import radii from "./Foundations/radius";
import spacing from "./Foundations/spacing";

import textStyles from "./Styles/textStyles";
import layerStyles from "./Styles/layerStyles";

import Button from "./Components/Button"; 
import Input from "./Components/Input";



const theme = extendTheme({

  colors,
  shadows,
  ...typography,
  radii,
  spacing,

  styles: {
    global: {
      ...global
    }
  },
  textStyles: {
    ...textStyles,
  },
  layerStyles: {
    ...layerStyles,
  }, 
  components: {
    Button,
    Input,
  },
})

export default theme;