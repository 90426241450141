const global = {
    "body": {
      fontSize: "md",
      color: "gray.800",
      lineHeight: "tall",
      bg: "red.500",
    },
    a: {
      color: "red.500",
    }

  }

  export default global;