import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Box, Flex, Center, Text, HStack, Button, Img} from '@chakra-ui/react';
import {getQRCodeError, getQRCode, getQRCodePending, getStampCardsToBeActivated} from '../../Services/reducers';
import {ReactComponent as Logo} from '../../Theme/Assets/Logo/Logo.svg';
import {clearQRCode as clearQRCodeAction, setStampCardToActivateExplicit} from '../../Services/actions';

const QRCode = ({...props}) => {
  const {pending, error, qrCode, clearQRCode, stampCards, setStampCards} = props;
  const handleClearQr = () => {
    setStampCards(stampCards, -1, 0);
    clearQRCode();
  }
  return (
    <Flex justify="center" align="center" minHeight="100vh">
      <Button opacity="0" position="absolute" w={52} h={28} top="0" left="0" onClick={() => {handleClearQr()}}>
        Clear QR
      </Button>
      <HStack spacing="92px">
        <Box>
          <Center>
            <Logo />
          </Center>
          {!pending && !error && (
            <Center mt={12}>
              <Box layerStyle="boxNotice" p={6} align="center">
                {!!qrCode.description && (
                  <>
                    <Text textStyle={`caption`} color="white">
                      Skannaa tai aktivoi koodi sovelluksella ja...
                    </Text>
                    <Text mt={2} textStyle={`h3`} color="white">
                      {qrCode.description}
                    </Text>
                  </>
                )}
                {!qrCode.description && (
                  <Text mt={2} textStyle={`h3`} color="white">
                    Skannaa koodi sovelluksella ja lunasta etusi!
                  </Text>
                )}
              </Box>
            </Center>
          )}
        </Box>
        <Box>
          {!pending && !error && (
            <Box>
              <Box layerStyle="box" textAlign="center" mb={2}>
                <Img src={qrCode.qr_code} alt="" h="100%" p={6} w={64} />
              </Box>
              {!!qrCode.activation_code && (
                <Box layerStyle="box" p={6} textAlign="center">
                  <Text textStyle={`h1`}>{qrCode.activation_code}</Text>
                </Box>
              )}
            </Box>
          )}
        </Box>
      </HStack>
    </Flex>
  );
};

const mapStateToProps = (state) => ({
  error: getQRCodeError(state),
  qrCode: getQRCode(state),
  pending: getQRCodePending(state),
  stampCards: getStampCardsToBeActivated(state)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearQRCode: clearQRCodeAction,
      setStampCards: setStampCardToActivateExplicit
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(QRCode);
